@layer base {
  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;
  }

  p {
    @apply mb-2;
  }

  ul {
    @apply mb-2 pl-4 list-disc;
  }
}
