/*
 * SWP Color Codes (taken from website)
 */

/* Primary base color (blue) */
$swp-primary: #2f689b;

/* Secondary base color (orange) */
$swp-secondary: rgb(179, 124, 0);

/* Secondary color, lightened */
$swp-secondary-light: rgb(242, 235, 217);

/* Light gray background color */
$swp-light-gray: rgb(244, 244, 244);

/* Link color (blue) */
$swp-link-color: #0057a8;
