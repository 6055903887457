table {
  td {
    font-variant: tabular-nums;
  }

  tr.disabled td {
    color: $pt-text-color-disabled;
  }

  td.text-right,
  th.text-right {
    text-align: right;
  }
}
